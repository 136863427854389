import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Image, { ImageType } from '../components/image';
import SEO from '../components/seo';
import { Section, Title, Text, ButtonLink } from '../components/components';
import styled from 'styled-components';
import usePosition from '../components/usePosition';
import Fade from 'react-reveal/Fade';
import Hero from '../components/backgroundSection';

const CustomSection = styled(Section)`
  .max-width-442 {
    max-width: 442px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .mt-12 {
    margin-top: 12px;
  }
  .pb-12 {
    padding-bottom: 12px;
  }
  .pb-36 {
    padding-bottom: 36px;
  }
  .pt-36 {
    padding-top: 36px;
  }
  .text-description {
    margin: 10px 0px;
  }
  .pb-40 {
    padding-bottom: 40px;
  }
  .width-276 {
    width: 276px;
  }
  .demo-description {
    padding-top: 40px;
  }
  .image-section {
    width: 343px;
    height: 747px;
  }
  @media (min-width: 768px) {
    .max-width {
      max-width: 300px;
    }
    .demo-description {
      padding-top: 0px;
    }
    .image-section {
      width: 275.68px;
      height: 600px;
    }
  }
  @media (min-width: 992px) {
    .max-width {
      max-width: 350px;
    }
    .demo-description {
      padding-top: 0px;
      max-width: 442px;
    }
    .image-section {
      width: 275.68px;
      height: 600px;
    }
  }
  .max-width-758 {
    max-width: 758px;
  }
  .mr-auto {
    margin-right: auto;
  }
  .ml-auto {
    margin-left: auto;
  }
`;

const RowImage = styled.div`
  background: ${props => props.bgColor || 'white'};
  clip-path: inset(0 0 0 0);
  padding-right: 16px;
  .image {
    margin-top: 40px !important;
    max-width: 343px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 768px) {
    .image {
      margin-top: 40px !important;
      width: 287px;
      height: 573px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 992px) {
    .image-desktop {
      margin-top: 80px !important;
      width: 443px;
      height: 887px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
const RowTable = styled.div`
  background: ${props => props.bgColor || 'white'};
  padding-top: 40px;
  &:last-child {
    padding-bottom: 80px;
  }
  .p-text-mobile {
    padding-top: 24px;
  }
  @media (min-width: 768px) {
    padding-top: 80px;
    &:last-child {
      padding-bottom: 80px;
    }
    .p-text-mobile {
      padding-top: 0px;
    }
  }
  @media (min-width: 992px) {
    &:last-child {
      padding-bottom: 80px;
    }
  }
`;

const TableContainer = styled.div`
  height: 936px;
  .text-height {
    height: 456px;
    padding-top: 80px;
  }
  @media (min-width: 768px) {
    height: 469px;
    .text-height {
      height: 469px;
      padding-top: 0px;
    }
  }
  @media (min-width: 992px) {
    height: 900px;
    .text-height {
      height: 900px;
    }
  }
`;
const ImageSection = styled(Section)`
  height: 738px;
`;

const BackgroundContainer = styled.div`
  background-image: url(../../img/trapezoid.png);
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  width: 559px;
  height: 80%;
  position: absolute;
  top: 40px;
  right: 0;

  @media (min-width: 768px) {
    background-position: right center;
    width: 700px;
    height: 960px;
    top: 80px;
  }
  @media (min-width: 992px) {
    width: 874px;
    height: 960px;
    top: 0;
  }
`;
export default function Template({ data, location }) {
  const { appsMobileGamesJson } = data;
  const { template } = appsMobileGamesJson;
  usePosition();
  return (
    <Layout
      navbarBackground={template.headerBackgroundColor}
      navbarColor={template.headerFontColor}
      layoutBackground={template.primaryBackgroundColor}
    >
      <SEO title={appsMobileGamesJson?.projectName} />
      <CustomSection
        padding="150px 0px 100px 0px"
        mobilePadding="150px 0px 100px 0px"
        tabletPadding="150px 0px 100px 0px"
        className="overflow-hidden custom-container"
        bgColor={template.primaryBackgroundColor}
      >
        <div className="row g-0 gutt-16 outt-40">
          <div className="col-8 col-md-6 offset-md-1 col-lg-5 offset-lg-1  pb-40">
            <Fade left>
              <div className="d-flex flex-column justify-content-center h-100 max-width-442">
                <Title
                  desktopsize="48px"
                  tabletsize="48px"
                  mobilesize="36px"
                  lineheightdesktop="57.6px"
                  lineheighttablet="57.6px"
                  lineheightmobile="36px"
                  color={template.primaryFontColor.title}
                  className="mb-12 pb-12"
                >
                  {template.firstSection.title}
                </Title>
                {template.firstSection.description.length !== 0 &&
                  template.firstSection.description.map((text, textId) => {
                    return (
                      <Text
                        key={textId}
                        size="16px"
                        lineheight="19px"
                        className="text-description"
                        color={template.primaryFontColor.text}
                      >
                        {text}
                      </Text>
                    );
                  })}
              </div>
            </Fade>
          </div>
          <Fade right>
            <div className="col-8 col-md-8 col-lg-6 section-image px-0">
              <div className="h-100">
                <Image
                  src={template.firstSection.image}
                  alt={template.firstSection.altText}
                  type={ImageType.Fluid}
                  className="max-width-758 m-auto"
                />
              </div>
            </div>
          </Fade>
        </div>
      </CustomSection>
      <CustomSection
        padding="100px 0px 50px 0%"
        mobilePadding="25px 0px"
        className="overflow-hidden custom-container"
        bgColor={template.primaryBackgroundColor}
      >
        <div className="row g-0 guttr-16 outtr-40">
          <Fade left>
            <div className="col-8 col-md-4 col-lg-6 section-image order-2 order-md-1 px-0">
              <Image
                src={template.secondSection.image}
                alt={template.secondSection.altText}
                type={ImageType.Fluid}
                className="max-width-758 mr-auto"
              />
            </div>
          </Fade>
          <div className="col-8 col-md-4 col-lg-5 offset-lg-1 order-1 order-md-2  px-0 mb-12">
            <Fade right>
              <div className="d-flex flex-column justify-content-center h-100 max-width-442 guttl-16">
                <Title
                  color={template.primaryFontColor.title}
                  desktopsize="24"
                  tabletsize="24px"
                  mobilesize="24px"
                  lineheightdesktop="28.8px"
                  lineheighttablet="28.8px"
                  lineheightmobile="24px"
                  weight="400"
                  className="mb-12 pb-12"
                >
                  {template.secondSection.title}
                </Title>
                {template.secondSection.description.length !== 0 &&
                  template.secondSection.description.map((text, textId) => {
                    return (
                      <Text
                        key={textId}
                        size="16px"
                        lineheight="19px"
                        className="text-description"
                        color={template.primaryFontColor.text}
                      >
                        {text}
                      </Text>
                    );
                  })}
              </div>
            </Fade>
          </div>
        </div>
      </CustomSection>
      <CustomSection
        padding="0px 0px"
        margin="0px 0px 130px 0px"
        mobilePadding="0px 0px"
        tabletPadding="0px 0px"
        className="overflow-hidden custom-container"
        bgColor={template.secondaryBackgroundColor}
      >
        <TableContainer className="position-relative row g-0 guttl-16 outt-40">
          <div className="col-8 col-md-6 col-lg-6 position-relative d-flex align-items-center text-height guttr-16">
            <Fade left>
              <div className="position-relative w-100">
                {template.thirdSection.table.length > 0 &&
                  template.thirdSection.table.map((row, index) => {
                    return (
                      <RowTable
                        className="row"
                        key={index}
                        bgColor={template.secondaryBackgroundColor}
                      >
                        <div className="col-8 col-md-3 col-lg-3 offset-lg-2">
                          <Title
                            desktopsize="24px"
                            tabletsize="24px"
                            mobilesize="24px"
                            lineheightdesktop="24px"
                            lineheighttablet="24px"
                            lineheightmobile="24px"
                            weight="400"
                            color={template.secondaryFontColor.title}
                          >
                            {row.title}
                          </Title>
                        </div>
                        <div className="col-md-5 col-lg-7 max-width p-text-mobile">
                          <Text
                            size="16px"
                            lineheight="19px"
                            color={template.secondaryFontColor.title}
                          >
                            {row.description}
                          </Text>
                        </div>
                      </RowTable>
                    );
                  })}
              </div>
            </Fade>
          </div>
          <RowImage
            className="col-8 col-md-2 col-lg-6 d-lg-none"
            bgColor={template.secondaryBackgroundColor}
          >
            <Fade bottom>
              <div className="image">
                <Image
                  src={template.thirdSection.image}
                  alt={template.thirdSection.altText}
                  type={ImageType.Fluid}
                  className="m-auto "
                />
              </div>
            </Fade>
          </RowImage>
          <RowImage
            className="d-none d-lg-flex col-lg-6"
            bgColor={template.secondaryBackgroundColor}
          >
            <Fade bottom>
              <div className="h-100 image-desktop">
                <Image
                  src={template.thirdSection.image}
                  alt={template.thirdSection.altText}
                  type={ImageType.Fluid}
                  className="m-auto "
                />
              </div>
            </Fade>
          </RowImage>
        </TableContainer>
      </CustomSection>
      <ImageSection
        padding="0px 0%"
        tabletPadding="120px 0px"
        mobilePadding="112px 0px"
        className="overflow-hidden w-100 custom-container"
        bgColor={template.primaryBackgroundColor}
      >
        <Fade>
          <div className="col-8 image d-md-none px-0">
            <Hero
              fileName={template.fourthSection.image}
              ariaLabel={template.fourthSection.altText}
              style={{
                backgroundPositionX: '-203px',
                backgroundPositionY: 'top',
                backgroundSize: 'cover',
                height: 480,
              }}
              tag="div"
            />
          </div>
          <div className="col-md-8 d-none d-md-flex image d-lg-none px-0">
            <Hero
              fileName={template.fourthSection.image}
              ariaLabel={template.fourthSection.altText}
              style={{
                backgroundPositionX: '-121px',
                backgroundPositionY: 'top',
                backgroundSize: 'cover',
                height: 480,
              }}
              tag="div"
            />
          </div>
          <div className="col-lg-12 image d-none d-lg-flex px-0">
            <Hero
              fileName={template.fourthSection.image}
              ariaLabel={template.fourthSection.altText}
              style={{
                backgroundPositionX: 'center',
                backgroundSize: 'contain',
              }}
              tag="div"
            />
          </div>
        </Fade>
      </ImageSection>
      <CustomSection
        padding="0px 0%"
        margin="100px 0px"
        tabletPadding="0px 0px"
        mobilePadding="0px 0px"
        className="w-100 custom-container position-relative"
        bgColor={template.primaryBackgroundColor}
      >
        <BackgroundContainer role="img" aria-label="trapezoid background" />
        <div className="row position-relative g-0 gutt-16 outt-40">
          <div className="col-8 col-md-4 col-lg-6 d-flex justify-content-start justify-content-lg-center position-relative px-0">
            <Fade left>
              <div className="position-relative image-section">
                <Image
                  src={template.fifthSection.image}
                  alt={template.fifthSection.altText}
                  type={ImageType.Fluid}
                />
              </div>
            </Fade>
          </div>

          <div className="col-8  col-md-4 col-lg-6 offset-lg-0  position-relative ">
            <div className="offset-lg-1 h-100 d-flex align-items-center position-relative">
              <Fade right>
                <div className="demo-description">
                  <Title
                    desktopsize="24px"
                    tabletsize="24px"
                    mobilesize="24px"
                    lineheightdesktop="24px"
                    lineheighttablet="24px"
                    lineheightmobile="24px"
                    color={template.primaryFontColor.title}
                    className="mb-12 pb-12"
                  >
                    {template.fifthSection.title}
                  </Title>
                  {template.fifthSection.description.length !== 0 &&
                    template.fifthSection.description.map((text, textId) => {
                      return (
                        <Text
                          key={textId}
                          size="16px"
                          lineheight="19px"
                          className="text-description pb-36 mb-0"
                          color={template.primaryFontColor.text}
                        >
                          {text}
                        </Text>
                      );
                    })}
                  <Title
                    desktopsize="24px"
                    tabletsize="24px"
                    mobilesize="24px"
                    lineheightdesktop="24px"
                    lineheighttablet="24px"
                    lineheightmobile="24px"
                    weight="400"
                    className="pb-12"
                    color={template.primaryFontColor.title}
                  >
                    {template.fifthSection.demoDescription}
                  </Title>
                  <div className="d-flex justify-content-start mt-5 flex-column pt-36">
                    {template.fifthSection.links.length !== 0 &&
                      template.fifthSection.links.map((lk, index) => {
                        return (
                          <React.Fragment key={index}>
                            {lk.url && lk.label && (
                              <ButtonLink
                                to={lk.url}
                                className="btn-purple mx-2 mt-12 mb-12 fit-content"
                                title={lk.title}
                              >
                                {lk.label}
                              </ButtonLink>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </CustomSection>
      <CustomSection
        padding="200px 0px"
        mobilePadding="144px 0px"
        tabletPadding="144px 0px"
        className="overflow-hidden w-100"
        bgColor={template.primaryBackgroundColor}
      >
        <Fade bottom>
          <div className="gutt-16 outt-40">
            <div>
              <Title
                desktopsize="24px"
                tabletsize="24px"
                mobilesize="24px"
                lineheightdesktop="29px"
                lineheighttablet="29px"
                lineheightmobile="29px"
                color={template.primaryFontColor.title}
                align="center"
              >
                {template.lastSection.title}
              </Title>
            </div>
            <div className="d-flex justify-content-center mt-5 flex-wrap flex-md-nowrap">
              {template.lastSection.links.length !== 0 &&
                template.lastSection.links.map((lk, index) => {
                  return (
                    <React.Fragment key={index}>
                      {lk.url && lk.label && (
                        <ButtonLink
                          to={lk.url}
                          className="btn-white mx-2 mt-12 mb-12 fit-content"
                          title={lk.title}
                        >
                          {lk.label}
                        </ButtonLink>
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </Fade>
      </CustomSection>
    </Layout>
  );
}
export const pageQuery = graphql`
  query($slug: String!) {
    appsMobileGamesJson(slug: { eq: $slug }) {
      slug
      projectName
      template {
        primaryBackgroundColor
        primaryFontColor {
          title
          text
        }
        secondaryBackgroundColor
        secondaryFontColor {
          title
          text
        }
        headerBackgroundColor
        headerFontColor
        firstSection {
          title
          description
          image
          altText
        }
        secondSection {
          title
          description
          image
          altText
        }
        thirdSection {
          image
          altText
          table {
            title
            description
          }
        }
        fourthSection {
          image
          altText
        }
        fifthSection {
          image
          altText
          title
          description
          demoDescription
          links {
            label
            url
            title
          }
        }
        lastSection {
          title
          links {
            label
            url
            title
          }
        }
      }
    }
  }
`;
